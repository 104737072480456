import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

function Index() {

    const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");


  const sendMail = async (name, email, subject, msg) => {
      const html = `<div><strong>Name : </strong> ${name}</div> <br />
                  <div><strong>Email : </strong> ${email}</div> <br />
                  <div><strong>Subject : </strong> ${subject}</div> <br /> 
                  <div><strong>Message : </strong> ${msg}</div>
                  `;
    try {
    const config = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
      body: JSON.stringify({
        recipientEmailId:process.env.REACT_APP_TO_EMAIL,
          subject:`Sani Tech Pest Inc Contact Us`,
          html:html
        })
    }
    const response = await fetch(process.env.REACT_APP_SEND_EMAIL_URL, config)
    if (response.ok) {
       setLoader(false);
        setMessage("your message has been sent successfully");
        formik.resetForm();
        setTimeout(() => {
          setMessage("");
        }, 5000);
    } else {
        throw "email sent failed"
    }
} catch (error) {
        setMessage(error);
        setLoader(false);
        setTimeout(() => {
          setMessage("");
        }, 5000);
    }
    
           
  }
  

    const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your name"),
    subject: Yup.string().required("Please select your subject"),
    email: Yup.string()
      .email("Invalid email")
      .required("Please enter your email"),
    message: Yup.string().required("Please enter your message"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoader(true);
      sendMail(
        values?.name,
        values?.email,
        values?.subject,
        values?.message
      );
    },
  });


  return (
    <>
      <section
        className="page-title"
        style={{ backgroundImage: "url(/assets/images/background/bg-3.jpg)" }}
      >
        <div className="auto-container">
          <h1>Contact Us</h1>
          <div className="text">Treating Customers Like Family Since 2004</div>
        </div>
      </section>

      <section className="contact-info-section-two">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sec-title mb-30">
                <div className="sub-title">Contact Us</div>
                <h2>
                  Our Contacts 
                </h2>
              </div>
              <div className="row">
            <div className="col-lg-4">
              <div className="info-wrapper">
                <div className="info-block">
                  <h4>Contact info</h4>
                  <div className="text mb-2">
                        4165 White Plains Road
                        <br />
                        P.O BOX 661527
                        <br />
                        Bronx ,NY 10466
                  </div>
                  <div className="email">
                    <a href="mailto:sean.wells@sanitechpest.com">sean.wells@sanitechpest.com</a>
                  </div>
                  <div className="phone">
                    <a href="tel:7186552118">718-655-2118</a>
                  </div>
                </div>
              </div>
                </div>
                 <div className="col-lg-4">
              <div className="info-wrapper">
                <div className="info-block">
                  <h4>Opening hours</h4>
                  <div className="text">
                    Daily: 10.00 AM – 05.00 PM <br /> Sunday & Holidays : Closed
                  </div>
                    </div>
                      <div className="info-block">
                  <h4>Inspection hours</h4>
                  <div className="text">
                    Daily: 08.00 AM – 05.00 PM <br /> Sunday & Holidays : Closed
                  </div>
                </div>
              </div>
                </div>
                   <div className="col-lg-4">
              <div className="info-wrapper">
                <div className="info-block">
                  <h4>Social contact</h4>
                  <ul className="social-links">
                    <li>
                      <a href={process.env.REACT_APP_FACEBOOK_LINK} target="_blank" className="facebook"  rel="noreferrer">
                        <span className="fab fa-facebook-f"></span>
                      </a>
                    </li>
                    <li>
                      <a href={process.env.REACT_APP_INSTAGRAM_LINK} target="_blank" className="instagram"  rel="noreferrer">
                        <span className="fab fa-instagram"></span>
                      </a>
                    </li>
                    <li>
                      <a href={process.env.REACT_APP_YOUTUBE_LINK} target="_blank" className="google-plus"  rel="noreferrer">
                        <span className="fab fa-youtube"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
                </div>
              </div>
              </div>
            {/* <div className="col-lg-8">
              <div className="text mb-50 mt-5">
                At Pestico Pest Control LLC, we are determined to keep your home
                free of pests. Give us a call at 210 763-6992 or fill out the
                form below to take service. We’ll provide you with a free
                estimate.
              </div>


              <div className="contact-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3016.2213743235397!2d-73.86218384913941!3d40.88895862225273!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f324022dcf29%3A0x95511fe87fa691ff!2sSani%20Tech%20Pest%20Inc!5e0!3m2!1sen!2sin!4v1689768573709!5m2!1sen!2sin"
                  width="600"
                  height="430"
                  frameborder="0"
                  style={{ border: 0, width: "100%" }}
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className="contact-form-section">
        <div className="auto-container">
          <div className="sec-title text-center">
            <h2>
              <span>Have A Question??</span>
            </h2>
          </div>
          <div className="contact-form">
             <form   id="contact-form" onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="form-group col-md-12">
                  <input
                          type="text"
                          name="name"
                          id="name"
                          className="form-control"
                          onChange={formik.handleChange}
                          value={formik?.values?.name}
                          placeholder="Name"
                        />
                        {formik?.errors?.name && formik?.touched?.name ? (
                          <div className="error-text ml-3">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.name}</li>
                            </ul>
                          </div>
                        ) : null}

                </div>
                <div className="form-group col-md-6">
                 <input
                          type="email"
                          name="email"
                          id="email"
                          className="form-control"
                          onChange={formik.handleChange}
                          value={formik?.values?.email}
                          placeholder="Email"
                        />
                        {formik?.errors?.email && formik?.touched?.email ? (
                          <div className="error-text ml-3">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.email}</li>
                            </ul>
                          </div>
                        ) : null}
                </div>
                <div className="form-group col-md-6">
                <input
                          type="text"
                          name="subject"
                          id="msg_subject"
                          className="form-control"
                          placeholder="Subject"
                          onChange={formik.handleChange}
                          value={formik?.values?.subject}
                        />
                        {formik?.errors?.subject && formik?.touched?.subject ? (
                          <div className="error-text ml-3">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.subject}</li>
                            </ul>
                          </div>
                        ) : null}
                </div>
                <div className="form-group col-md-12">
                   <textarea
                          name="message"
                          className="form-control"
                          id="message"
                          cols="30"
                          rows="6"
                          placeholder="Your Message"
                          onChange={formik.handleChange}
                          value={formik?.values?.message}
                        ></textarea>
                        {formik?.errors?.message && formik?.touched?.message ? (
                          <div className="error-text ml-3">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.message}</li>
                            </ul>
                          </div>
                        ) : null}
                </div>
                <div className="form-group col-md-12">
                  <div className="text-center">
                    <input
                      id="form_botcheck"
                      name="form_botcheck"
                      className="form-control"
                      type="hidden"
                      value=""
                    />
                    <button
                      className="theme-btn btn-style-one"
                      type="submit"
                      data-loading-text="Please wait..."
                    >
                       {loader && (
                          <div
                            className="spinner-border text-dark mr-3"
                            role="status"
                          >
                          </div>
                         )}
                      <span>Send Message </span>
                      
                    </button>
                    
                  </div>
                    {message && (
                    <div className="mt-3 text-center h5">{message }</div>
                       )} 
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
