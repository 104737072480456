import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./Root";
import Home from "./pages/Home";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import Faqs from "./pages/Faq";
import NotFound from "./pages/404";
import Services from "./pages/Services";
import American from "./pages/ServiceDetails/American"
import German from "./pages/ServiceDetails/German"
import Ants from "./pages/ServiceDetails/Ants"
import BedBugs from "./pages/ServiceDetails/BedBugs"
import Mice from "./pages/ServiceDetails/Mice"
import Rat from "./pages/ServiceDetails/Rat"
import Fly from "./pages/ServiceDetails/Fly"
import Bees from "./pages/ServiceDetails/Bees"
import Appointment from "./pages/Appointment"
import ThankYou from "./pages/ThankYou"
import Product from "./pages/Product"
import "./App.css";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
         
         {
          path: "/services",
          element: <Services />,
        },
         {
          path: "/services/german-roaches",
          element: <German />,
        },
        {
          path: "/services/american-roaches",
          element: <American />,
        },
          {
          path: "/services/ants",
          element: <Ants />,
        },
          {
          path: "/services/bed-bugs",
          element: <BedBugs />,
        },
          {
          path: "/services/mice",
          element: <Mice />,
        },
          {
          path: "/services/rat",
          element: <Rat />,
        },
        {
          path: "/services/fly",
          element: <Fly />,
        },
        {
          path: "/services/bees",
          element: <Bees />,
        },
        {
          path: "/about-us",
          element: <About />,
        },
        {
          path: "/faqs",
          element: <Faqs />,
        },
        {
          path: "/contact-us",
          element: <ContactUs />,
        },
         {
          path: "/appointment",
          element: <Appointment />,
        },
         {
          path: "/thank-you",
          element: <ThankYou />,
        },
         {
          path: "/products",
          element: <Product />,
        },
        { path: "*", element: <NotFound /> },
      ],
    },
  ]);

  return (
    <>
      {/* <div className="page-wrapper"> */}
        <RouterProvider router={router} />
      {/* </div> */}
    </>
  );
}

export default App;
