import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams,  } from "react-router-dom";
import Paginate from "../../component/Pagination";
import { fetchProduct } from "../../store/product/productSlice";

function Index() {
const [searchParams] = useSearchParams();
const dispatch = useDispatch();
const navigate = useNavigate();
const [page, setPage] = useState(1);
const productList = useSelector((state) => state?.product?.list);
const isLoader = useSelector((state) => state?.product?.isLoader);

  useEffect(() => {
            urlHandler(searchParams.get('page'))
  }, []);

    const urlHandler = (page) => {
    dispatch(fetchProduct(`?page=${page || 1}&limit=18`));
    navigate(`/products?page=${page || 1}`)
    document?.documentElement?.scrollTo({ top: 0, behavior: "smooth" });
    setPage(page || 1)
  }

  return (
      <>
        {isLoader &&  <div className="preloader"></div>}
      <section
        className="page-title"
        style={{ backgroundImage: "url(/assets/images/background/bg-3.jpg)" }}
      >
        <div className="auto-container">
          <h1>Products</h1>
          <div className="text">Treating Customers Like Family Since 2004</div>
        </div>
      </section>
   <section className="blog-section">
        <div className="auto-container">
                  <div className="row">
                      {productList?.docs?.length > 0 && productList?.docs?.map((item, index) => {
                          return (
                              <div className="col-lg-4 news-block-one" key={index}>
                                  <div className="inner-box">
                                      <div className="image"><a href={item?.link} target="_blank"  rel="noreferrer">
                                          <img src={item?.image} alt="" /></a></div>
                                      <h4><a href={item?.link} target="_blank"  rel="noreferrer">{item?.title}</a></h4>
                                      <div className="post-meta">Price <span>  ${item?.price}</span></div>
                                      <div className="link-btn"><a href={item?.link} target="_blank"  rel="noreferrer" className="theme-btn btn-style-one style-two"><span>Shop Now</span></a></div>
                                  </div>
                              </div>)
                      })}
                  </div>
                   {productList?.totalPages > 1 && <Paginate paginate={productList} page={page} setPage={(data) => urlHandler(data)} />}
        </div>
    </section>
    </>
  );
}

export default Index;
