import {  NavLink } from "react-router-dom";

function Index() {
  return (
    <>
      <footer className="main-footer">
        <div className="upper-box">
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-4 col-md-7">
                <div className="widget about-widget">
                  <h4 className="widget_title">About Sani Tech Pest Inc.</h4>
                  <div className="text">
                  SANI-TECh PEST INC. is a full service I.P.M pest control
                  company located in the BRONX providing service to all 5
                  boroughs for over 20 yrs. Our services are designed to fit the
                  needs of our customers. We provide green services as well
                  as pesticide applications Rodent Proofing, Roaches, Mice, Flies, Bees,
                  Rats and more.
                </div>
                  <div className="copyright">
                    <a href="#"  rel="noreferrer">Sani Tech Pest Inc.</a> © 2023 All Right Reserved
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="widget links-widget">
                  <h4 className="widget_title">Pest Control</h4>
                  <div className="widget-content">
                      <ul className="list">
                      <li>
                            <NavLink to="/services/german-roaches">German Roach</NavLink>
                          </li>
                          <li >
                            <NavLink to="/services/american-roaches">American Roach</NavLink>
                          </li>
                          <li>
                            <NavLink to="/services/ants">Ant</NavLink>
                          </li>
                          <li>
                            <NavLink to="/services/bed-bugs">Bedbugs</NavLink>
                          </li>
                          <li>
                            <NavLink to="/services/mice">Mice</NavLink>
                          </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="widget links-widget">
                  <h4 className="widget_title">Usefull Link</h4>
                  <div className="widget-content">
                    <ul className="list">
                      <li>
                          <NavLink to="/services">Pest Services</NavLink>
                      </li>
                       <li >
                         <NavLink to="/products">Products</NavLink>
                  </li>
                       <li>
                          <NavLink to="/appointment">Appointment</NavLink>
                      </li>
                        <li>
                         <NavLink to="/faqs">FAQ</NavLink>
                      </li>
                      <li>
                         <NavLink to="/contact-us">Contact Us</NavLink>
                      </li>
                    </ul>
                  
                  </div>
                </div>
              </div>
              <div className="col-lg-3 offset-lg-1 col-md-6">
                <div className="widget contact-widget">
                  <h4 className="widget_title">Contact</h4>
                  <ul>
                    <li>
                      4165 White Plains Road
                      <br />
                      P.O BOX 661527
                      <br />
                      Bronx ,NY 10466
                    </li>
                    <li>
                      <a href="mailto:sean.wells@sanitechpest.com">sean.wells@sanitechpest.com</a>
                    </li>
                    <li>
                      <a href="tel:7186552118">718-655-2118</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer-bottom">
        <div className="auto-container">
          <div className="content">
            <div className="logo">
              <a href="index-2.html">
                <img src="/assets/images/white-logo.png" alt="" />
              </a>
            </div>
            <ul className="social-icon">
              <li>
                <a href={process.env.REACT_APP_FACEBOOK_LINK} target="_blank"  rel="noreferrer">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href={process.env.REACT_APP_INSTAGRAM_LINK} target="_blank"  rel="noreferrer">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href={process.env.REACT_APP_YOUTUBE_LINK} target="_blank"  rel="noreferrer">
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
            </ul>
            <div className="footer-menu">
              <ul>
                <li className="text-white">
                 Developed by <a href="https://www.vibrantprintshop.com" target="_blank"  rel="noreferrer"> vibrantprintshop.com</a> 
                  {/* <a href="#">Terms of Service </a> */}
                </li>
                {/* <li>
                  <a href="#">Privacy Policy</a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
