import Card from "../../component/Card";

function Index() {

    return (
        <>
            <section className="page-title" style={{ backgroundImage: "url(/assets/images/background/bg-3.jpg)" }}>
        <div className="auto-container">
            <h1>Services</h1>
            <div className="text">Treating Customers Like Family Since 2004</div>
        </div>
    </section>

    <section className="services-section style-two">
        <div className="auto-container">
            <div className="wrapper-box">
                <span className="shape-three"><img src="/assets/images/shape/shape-6.png" alt="" /></span>
                <span className="shape-four"><img src="/assets/images/shape/shape-7.png" alt="" /></span>
                        <div className="row">

                          <Card title="German Roach" iconName="flaticon-cockroach" text="" link="/services/german-roaches" />
              <Card title="American Roach" iconName="flaticon-cockroach" text="" link="/services/american-roaches" />
              <Card title="Ant" iconName="flaticon-ant" text="" link="/services/ants" />
              <Card title="Bedbugs" iconName="flaticon-mosquito" text="" link="/services/bed-bugs" />
              <Card title="Mice" iconName="flaticon-squirrel" text="" link="/services/mice" />
              <Card title="Rat" iconName="flaticon-squirrel" text="" link="/services/rat" />
              <Card title="Fly" iconName="flaticon-fly" text="" link="/services/fly" />
              <Card title="WASP" iconName="flaticon-bee" text="" link="/services/bees" />     
                </div>
            </div>
        </div>
    </section>

    <section className="whychoose-us-section">
        <div className="auto-container">
            <div className="row">
                <div className="col-lg-5">
                    <div className="sec-title mb-20">
                        <div className="sub-title">Why Choose us</div>
                        <h2>We Treat Your Home  <br />
Like We Treat Ours!</h2>                        
                    </div>
                            <div className="text mb-40">There are several factors that can encourage you to choose us.</div>
<div className="pricing-block">
                            <div className="content">
                            <ul>
                                 <li>We Have 20 Years Of Experience In Pest Control.</li>
                                <li>We Offer High-Quality Pest Control Services</li>
                                <li>Service commercial & residential properties</li>
                                <li>Fully licensed and insured technicians</li>
                                <li>Courteous service and effective solutions</li>
                                    </ul>   
                            </div>        
                        </div>
                    {/* <div className="icon-box">
                        <div className="icon"><span className="flaticon-settings"></span></div>
                        <div className="content">
                            <h4>Top Rated Services</h4>
                            <div className="text">Lorem ipsum dolor sit amet consect. elit sed do smod.</div>
                        </div>
                    </div>
                    <div className="icon-box">
                        <div className="icon"><span className="flaticon-pesticide"></span></div>
                        <div className="content">
                            <h4>Fully Equipmented</h4>
                            <div className="text">Lorem ipsum dolor sit amet consect. elit sed do smod.</div>
                        </div>
                    </div> */}
                </div>
                <div className="col-lg-6 offset-lg-1">
                    <div className="image-wrapper">
                        {/* <span className="shape-one"><img src="/assets/images/shape/shape-2.png" alt="" /></span>
                        <span className="shape-two"><img src="/assets/images/shape/shape-3.png" alt="" /></span> */}
                        <div className="image">
                            <img src="/assets/images/resource/image-3.jpg" alt="" />
                        </div>
                        {/* <div className="author-info" data-parallax='{"y": 40}'>
                            <div className="author"><img src="/assets/images/resource/author-1.jpg" alt="" /></div>
                            <h4>Years of Experiences</h4>
                            <div className="link"><a href="#" className="theme-btn btn-style-one style-three"><span>Expeart</span></a></div>
                        </div> */}
                        <a href="tel:7186552118" className="phone">
                            <img src="/assets/images/icons/icon-3.png" alt="" />
                            <div className="hover-image"><img src="/assets/images/icons/icon-3.png" alt="" /></div>
                        </a>
                        <a className="email" href="mailto:sean.wells@sanitechpest.com">
                            <img src="/assets/images/icons/icon-4.png" alt="" />
                            <div className="hover-image"><img src="/assets/images/icons/icon-4.png" alt="" /></div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>

   </>
  );
}

export default Index;
