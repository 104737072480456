function Index() {
  return (
    <>
      <section className="funfacts-section mb-5" style={{ backgroundImage: "url(/assets/images/background/bg-1.jpg)" }}>
        <div className="auto-container">
            <div className="row">
                <div className="col-lg-4 col-md-6 funfact-block-one">
                    <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div className="content">
                            <div className="count-outer count-box">
                                <span className="count-text">20</span><span>+</span>
                            </div>
                            <div className="text">Years Experiences</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 funfact-block-one">
                    <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div className="content">
                            <div className="count-outer count-box">
                                <span className="count-text" >100</span><span>k+</span>
                            </div>
                            <div className="text">Services Done</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 funfact-block-one">
                    <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div className="content">
                            <div className="count-outer count-box">
                                <span className="count-text">100</span><span>k+</span>
                            </div>
                            <div className="text">Satisfied Clients</div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-3 col-md-6 funfact-block-one">
                    <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div className="content">
                            <div className="count-outer count-box">
                                <span className="count-text" >30</span><span>+</span>
                            </div>
                            <div className="text">Awards Achieve</div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    </section>
    </>
  );
}

export default Index;
