import ServiceBanner from "../../../component/ServiceBanner";
import ServiceSidebar from "../../../component/ServiceSidebar";

function Index() {
  return (
    <>
     <ServiceBanner title="Hornets And Other Stinging Insects" image="/assets/images/background/wasp.jpg" />
    <section className="service-details">
        <div className="auto-container">
            <div className="row">
                <div className="col-lg-8 order-lg-2">
                    <div className="context-block">
                        <h2>Hornets And Other Stinging Insects</h2>
                        <div className="text">
                                  <p>
                                     Our service for stinging insect is largely dependant on the pest, our goal will be to eradicate the nest or hive and some cases aid in the removal of a colony.

                                  </p>
                                  <p>
                                    Yellow jackets are among the most common species of wasps in the United States, along with paper wasps, red wasps, mud daubers, and ground wasps. Wasps vary in he European hornet is the only species of hornet to live in the United States.
                                  </p>
                                   <div className="text about-us-section pt-0 pb-0"><ul className="list">
                      <li>The European hornet can grow to be 1.5 inches long.</li>
                      <li>Like wasps, the hornet has 2 sets of wings, 6 legs, 2 antennae, and a pinched-in waist.</li>
                      <li>European hornets are brown with yellow and orange stripes. Their wings are gold-brown.</li>
                      <li>European hornets can sting many times as their stingers are not barbed.</li>
                      <li>European hornets can be found across the United States.</li>
                    </ul></div>
                        </div>
                        {/* <div className="image-block">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="image mb-30"><img src="/assets/images/resource/image-10.jpg" alt="" /></div>
                                </div>
                                <div className="col-md-6">
                                    <div className="image mb-30"><img src="/assets/images/resource/image-11.jpg" alt="" /></div>
                                </div>
                            </div>
                        </div> */}
                        
                    </div>

 {/* <div className="context-block">
                        <h2>WASP</h2>
                        <div className="text">
                                  <p>
                                     Wasps are subdivided into two groups: solitary wasps, which live alone, and social wasps, which live in colonies. Of the tens of thousands of species of wasps that have been described, the vast majority are solitary in habit. The social wasps
are confined to about 1,000 species within the family Vespidae
(superfamily Vespoidea) and include the hornets and yellow jackets (yellowjackets). They differ from other wasp families in having their wings folded longitudinally when at rest.</p>
                        </div>
                        
                          </div> */}
                      </div>
                <div className="col-lg-4 sidebar-side left-sidebar">
                    <ServiceSidebar link="bees" />
                </div>
            </div>
        </div>
    </section>
    </>
  );
}

export default Index;
