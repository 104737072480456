import ServiceBanner from "../../../component/ServiceBanner";
import ServiceSidebar from "../../../component/ServiceSidebar";

function Index() {
  return (
      <>
          <ServiceBanner title="Ant Control" image="/assets/images/background/ants.jpg" />
    <section className="service-details">
        <div className="auto-container">
            <div className="row">
                <div className="col-lg-8 order-lg-2">
                    <div className="context-block">
                        <h2>Ant Control</h2>
                              <div className="text">
                                  <p>Once the ant is identified our treatment will include, but is not limited to sprays, gels, granular bait, exclusion and the removal or identification of harborage areas. </p>
                                  <p>
                                      Pavement ants are <sup>1</sup>&frasl;<sub>16</sub> to <sup>1</sup>&frasl;<sub>8</sub> inch long with a dark body and lighter colored legs. They have two small spines on the back portion of the thorax, two nodes in their petioles, and their bodies are covered with stiff hairs. Pavement ants are easily identified by the narrow, parallel grooves on their heads and thoraxes.
                                     </p>
                       <p>Little is known about the biology of this species. The developmental time from egg to adult is 36 to 63 days. Indoors, swarmers emerge anytime, and outdoors, emergence is in June and July.</p>
                              </div>
                        {/* <div className="image-block">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="image mb-30"><img src="/assets/images/resource/image-10.jpg" alt="" /></div>
                                </div>
                                <div className="col-md-6">
                                    <div className="image mb-30"><img src="/assets/images/resource/image-11.jpg" alt="" /></div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="col-lg-4 sidebar-side left-sidebar">
                   <ServiceSidebar link="ants" />
                </div>
            </div>
        </div>
    </section>
    </>
  );
}

export default Index;
