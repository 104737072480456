import { useState } from "react";
import DatePicker from "react-datepicker";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";

function Index() {
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");

  const sendMail = async (val) => {
    let date = new Date(val.date);
    let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
    let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

    const html = `<div><strong>Service Type : </strong> ${val.servicetype}</div> <br />
    <div><strong>Service Name : </strong> ${val.service}</div> <br />
    <div><strong>Name : </strong> ${val.firstname} ${val.lastname}</div> <br />
    <div><strong>Email : </strong> ${val.email}</div> <br />
    <div><strong>Phone : </strong> ${val.phone}</div> <br />
    <div><strong>Address : </strong> ${val.address}, ${val.state}, ${val.city}-${val.zipcode}</div> <br />
    <div><strong>Date & Time : </strong>${day}-${month}-${year} ${val.time}</div>
                  `;
    try {
    const config = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
      body: JSON.stringify({
        recipientEmailId:process.env.REACT_APP_TO_EMAIL,
          subject:`Sani Tech Pest Inc Appointment`,
          html:html
        })
    }
    const response = await fetch(process.env.REACT_APP_SEND_EMAIL_URL, config)
    if (response.ok) {
       setLoader(false);
        setMessage("your message has been sent successfully");
        formik.resetForm();
        setTimeout(() => {
          setMessage("");
        }, 5000);
    } else {
     throw "email sent failed"
    }
} catch (error) {
        setMessage(error);
        setLoader(false);
        setTimeout(() => {
          setMessage("");
        }, 5000);
    }
    
           
  }
  

  const validationSchema = Yup.object().shape({
      servicetype: Yup.string().required("Please select your service type"),
      service: Yup.string().required("Please select your service"),
    firstname: Yup.string().required("Please enter your first name"),
    lastname: Yup.string().required("Please enter your last name"),
    email: Yup.string()
      .email("Invalid email")
      .required("Please enter your email"),
    phone: Yup.string().required("Please enter your phone"),
    address: Yup.string().required("Please enter your street address"),
    city: Yup.string().required("Please enter your city"),
    state: Yup.string().required("Please enter your state"),
    zipcode: Yup.string().required("Please enter your zip code"),
    time: Yup.string().required("Please select your time"),
    date: Yup.string().required("Please enter your date"),

  });

  const formik = useFormik({
    initialValues: {
      servicetype:"",
      service:"",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      zipcode: "",
      time:"",
date:new Date()
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //  window.callback = callback;
      // loadScript(1, values?.email)
      // setLoader(true);
      sendMail(values);
      window.open('https://checkout.square.site/buy/OROBVUCOYVP3A4S3COQLXDXS?src=sms');
    },
  });



const disabledDate = (current) => {
  return new Date(current).getDay() !== 0
  };
  
//   function loadScript(amount, email) {
//   return new Promise(function (resolve, reject) {
//     var script = document.createElement("script");
//     script.id = "cardx_lightbox_script";
//     script.src = "https://test.lightbox.cardx.com/v1/lightbox.min.js";
//     script.setAttribute("data-account", "vprinttest");
//     script.setAttribute("data-amount", amount);
//     script.setAttribute("data-button", "#paycardx");
//     script.setAttribute("data-callback", "callback");
//     script.setAttribute("data-mode", "payment");
//     script.setAttribute("data-billingInclude", false);
//     script.setAttribute("data-billingEmail", email);
//     script.setAttribute("data-openOnLoad", true);
//     script.async = true;
//     script.addEventListener("load", function () {
//       resolve();
//     });
//     script.addEventListener("error", function (e) {
//       reject(e);
//     });
//     document.body.appendChild(script);
//   });
// }

//   function callback(err, message) {
//     if (err) {
//       alert(err);
//       return;
//     }
//       if (message?.pi_response_status === "success") {
//        return navigate("/thank-you");
//       }
//   }

  
  
  return (
    <>
      
      <section
        className="page-title"
        style={{ backgroundImage: "url(/assets/images/background/bg-3.jpg)" }}
      >
        <div className="auto-container">
          <h1>Appointment</h1>
          <div className="text">Treating Customers Like Family Since 2004</div>
        </div>
      </section>


      <section className="contact-form-section">
        <div className="auto-container">
          <div className="sec-title text-center">
            <h2>
             Our Pest Control Services
            </h2>
          </div>
          <div className="contact-form">
             <form   id="contact-form" onSubmit={formik.handleSubmit}>
              <div className="row">
                  <div className="form-group col-md-6">
                <label className="ml-3 font-weight-bold">What type of service are you requesting?</label>
                <div className="search-services-form1">
                   <select className="form-control" name="servicetype"
                          id="servicetype"
                          onChange={formik.handleChange}
                          value={formik?.values?.servicetype}>
                                <option value="">What type of service are you requesting?</option>
                                <option value="Residential">Residential</option>
                                <option value="Commercial">Commercial</option>
                    </select>
                     {formik?.errors?.servicetype && formik?.touched?.servicetype ? (
                          <div className="error-text ml-3">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.servicetype}</li>
                            </ul>
                          </div>
                        ) : null}
                    </div>
                </div>

                  <div className="form-group col-md-6">
                  <label className="ml-3 font-weight-bold">What service(s) are you interested in?</label>

                   <select className="form-control" name="service"
                          id="service"
                          onChange={formik.handleChange}
                          value={formik?.values?.service}>
                                <option value="">What service(s) are you interested in?</option>
                                <option value="German Roaches">German Roaches Control</option>
                                <option value="American Roaches">American Roaches Control</option>
                                <option value="Ants">Ants Control</option>
                                <option value="Bed Bugs">Bed Bugs Control</option>
                                <option value="Mice">Mice Control</option>
                                <option value="Rates">Rates Control</option>
                                <option value="Flies">Flies Control</option>
                                <option value="Honey Bee">Honey Bee Control</option>
                    </select>
                        {formik?.errors?.service && formik?.touched?.service ? (
                          <div className="error-text ml-3 ">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.service}</li>
                            </ul>
                          </div>
                        ) : null}

                </div>
                <div className="form-group col-md-6">
                  <label className="ml-3 font-weight-bold">First Name</label>
                  <input
                          type="text"
                          name="firstname"
                          id="firstname"
                          className="form-control"
                          onChange={formik.handleChange}
                          value={formik?.values?.firstname}
                          placeholder="First Name"
                        />
                        {formik?.errors?.firstname && formik?.touched?.firstname ? (
                          <div className="error-text ml-3 ">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.firstname}</li>
                            </ul>
                          </div>
                        ) : null}

                </div>
                   <div className="form-group col-md-6">
                  <label className="ml-3 font-weight-bold">Last Name</label>
                  <input
                          type="text"
                          name="lastname"
                          id="lastname"
                          className="form-control"
                          onChange={formik.handleChange}
                          value={formik?.values?.lastname}
                          placeholder="Last Name"
                        />
                        {formik?.errors?.lastname && formik?.touched?.lastname ? (
                          <div className="error-text ml-3 ">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.lastname}</li>
                            </ul>
                          </div>
                        ) : null}

                </div>
                <div className="form-group col-md-6">
                  <label className="ml-3 font-weight-bold">Email</label>
                 <input
                          type="text"
                          name="email"
                          id="email"
                          className="form-control"
                          onChange={formik.handleChange}
                          value={formik?.values?.email}
                          placeholder="Email"
                        />
                        {formik?.errors?.email && formik?.touched?.email ? (
                          <div className="error-text ml-3 ">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.email}</li>
                            </ul>
                          </div>
                        ) : null}
                </div>
                <div className="form-group col-md-6">
                  <label className="ml-3 font-weight-bold">Phone</label>
                <input
                          type="text"
                          name="phone"
                          id="phone"
                          className="form-control"
                          placeholder="Phone"
                          onChange={formik.handleChange}
                          value={formik?.values?.phone}
                        />
                        {formik?.errors?.phone && formik?.touched?.phone ? (
                          <div className="error-text ml-3 ">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.phone}</li>
                            </ul>
                          </div>
                        ) : null}
                </div>
                <div className="form-group col-md-12">
                  <label className="ml-3 font-weight-bold">Street Address</label>
                   <textarea
                          name="address"
                          className="form-control"
                          id="address"
                          cols="30"
                          rows="3"
                          placeholder="Street Address"
                          onChange={formik.handleChange}
                          value={formik?.values?.address}
                        ></textarea>
                        {formik?.errors?.address && formik?.touched?.address ? (
                          <div className="error-text ml-3 ">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.address}</li>
                            </ul>
                          </div>
                        ) : null}
                </div>
                  <div className="form-group col-md-4">
                  <label className="ml-3 font-weight-bold">City</label>
                <input
                          type="text"
                          name="city"
                          id="city"
                          className="form-control"
                          placeholder="City"
                          onChange={formik.handleChange}
                          value={formik?.values?.city}
                        />
                        {formik?.errors?.city && formik?.touched?.city ? (
                          <div className="error-text ml-3">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.city}</li>
                            </ul>
                          </div>
                        ) : null}
                </div>
                  <div className="form-group col-md-4">
                  <label className="ml-3 font-weight-bold">State / Province / Region</label>
                <input
                          type="text"
                          name="state"
                          id="state"
                          className="form-control"
                          placeholder="State / Province / Region"
                          onChange={formik.handleChange}
                          value={formik?.values?.state}
                        />
                        {formik?.errors?.state && formik?.touched?.state ? (
                          <div className="error-text ml-3">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.state}</li>
                            </ul>
                          </div>
                        ) : null}
                </div>
                  <div className="form-group col-md-4">
                  <label className="ml-3 font-weight-bold">ZIP / Postal Code</label>
                <input
                          type="text"
                          name="zipcode"
                          id="zipcode"
                          className="form-control"
                          placeholder="ZIP / Postal Code"
                          onChange={formik.handleChange}
                          value={formik?.values?.zipcode}
                        />
                        {formik?.errors?.zipcode && formik?.touched?.zipcode ? (
                          <div className="error-text ml-3">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.zipcode}</li>
                            </ul>
                          </div>
                        ) : null}
                </div>
                  <div className="form-group col-md-4">
                  <label className="ml-3 font-weight-bold">Choice Date</label>
                  <DatePicker className="form-control"  name="date"
                    id="date" 
                    selected={formik?.values?.date} minDate={new Date()} filterDate={disabledDate} onChange={(date) =>formik.setFieldValue("date", date) }
                           />
                        {formik?.errors?.date && formik?.touched?.date ? (
                          <div className="error-text ml-3">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.date}</li>
                            </ul>
                          </div>
                        ) : null}
                </div>
                  <div className="form-group col-md-4">
                  <label className="ml-3 font-weight-bold">Select a Time</label>
                  <select name="time" id="time" className="form-control" onChange={formik.handleChange}
                          value={formik?.values?.time}>
                    <option value="">Select a time</option>
                    <option value="08am - 10am">08am - 10am</option>
                    <option value="10am - 12pm">10am - 12pm</option>
                    <option value="01pm - 03pm">01pm - 03pm</option>
                    <option value="03pm - 05pm">03pm - 05pm</option>
                  </select>
                        {formik?.errors?.time && formik?.touched?.time ? (
                          <div className="error-text ml-3">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.time}</li>
                            </ul>
                          </div>
                        ) : null}
                </div>
                <div className="form-group col-md-12">
                  <div className="text-center">
                    <input
                      id="form_botcheck"
                      name="form_botcheck"
                      className="form-control"
                      type="hidden"
                      value=""
                    />
                    <button
                      className="theme-btn btn-style-one"
                      type="submit"
                      data-loading-text="Please wait..."
                    >
                       {loader && (
                          <div
                            className="spinner-border text-dark mr-3"
                            role="status"
                          >
                          </div>
                         )}
                      <span>Submit</span>
                      
                    </button>
                    
                  </div>
                    {message && (
                    <div className="mt-3 text-center h5">{message }</div>
                       )} 
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
