import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import HeaderMenu from "../HeaderMenu";

function Index() {
  return (
    <>
      <Helmet>
        <script src="/assets/js/script.js" />
      </Helmet>
      <header className="main-header">
        <div className="header-top">
          <div className="auto-container">
            <div className="inner-container">
              <div className="left-column">
                 <ul className="contact-info">
                  
                <li className="pr-2 mr-4">
                  <a href={process.env.REACT_APP_FACEBOOK_LINK} target="_blank"  rel="noreferrer">
                    <i className="fab fa-facebook-square"></i>
                  </a>
                </li>
                <li className="pr-2 mr-4">
                  <a href={process.env.REACT_APP_INSTAGRAM_LINK} target="_blank"  rel="noreferrer">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li className="pr-2 mr-4">
                  <a href={process.env.REACT_APP_YOUTUBE_LINK} target="_blank"  rel="noreferrer">
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
                </ul>
              </div>
              <div className="right-column">
                 <ul className="contact-info">
                  <li>
                    <a href="tel:7186552118">
                      <i className="flaticon-call"></i>718-655-2118
                    </a>
                  </li>
                  <li>
                    <a href="mailto:sean.wells@sanitechpest.com">
                      <i className="flaticon-email-1"></i>sean.wells@sanitechpest.com{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="header-upper">
         <HeaderMenu />
        </div>
        <div className="sticky-header">
          <div className="header-upper">
           <HeaderMenu />
          </div>
        </div>
        <div className="mobile-menu">
          <div className="menu-backdrop"></div>
          <div className="close-btn">
            <span className="icon flaticon-remove"></span>
          </div>
          <nav className="menu-box">
            <div className="nav-logo">
               <Link to={'/'}>
                <img src="/assets/images/logo-2.png" alt="" title="" />
              </Link>
            </div>
            <div className="menu-outer"></div>
            <div className="social-links">
              <ul className="clearfix">
                <li>
                  <a href={process.env.REACT_APP_FACEBOOK_LINK} target="_blank"  rel="noreferrer">
                    <span className="fab fa-facebook-square"></span>
                  </a>
                </li>
                <li>
                  <a href={process.env.REACT_APP_INSTAGRAM_LINK} target="_blank"  rel="noreferrer">
                    <span className="fab fa-instagram"></span>
                  </a>
                </li>
                <li>
                  <a href={process.env.REACT_APP_YOUTUBE_LINK} target="_blank"  rel="noreferrer">
                    <span className="fab fa-youtube"></span>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="nav-overlay">
          <div className="cursor"></div>
          <div className="cursor-follower"></div>
        </div>
      </header>
    </>
  );
}

export default Index;
