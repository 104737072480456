// ** Toolkit imports
import { configureStore } from "@reduxjs/toolkit";

// ** Reducers
import productReducer from "./product/productSlice";

export const store = configureStore({
  reducer: {
    product: productReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
