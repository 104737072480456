import { Link } from "react-router-dom";

function Index() {
  return (
    <>
     <section className="cta-section mt-5">
        {/* <div className="shape">
            <svg xmlns="http://www.w3.org/2000/svg" width="1920" height="500"><path fill-rule="evenodd" fill="#E9F7F2" d="M0 0h1920l-50 399.999c-3.964 27.615-22.122 48.944-50 50.001L0 499.999V0z"/></svg>
        </div> */}
        {/* <span className="shape-two"><img src="/assets/images/shape/shape-3.png" alt="" /></span> */}
        <div className="auto-container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="text-center">
                        <div className="image-block">
                            <div className="image wow fadeInUp" data-wow-duration="1500ms"><img src="assets/images/resource/image-8.jpg" alt="" /></div>
                            {/* <div className="offer" data-parallax='{"x": 50}'>
                                20 <h4>% <br /> <span>OFF</span></h4>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="content wow fadeInUp" data-wow-duration="1500ms">
                        <h2>Book an <br /> Appointment Today!</h2>
                        {/* <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt labore dolore magna aliqua enim ad </div> */}
                        <div className="link-btn"><Link to="/appointment" className="theme-btn btn-style-one"><span> Get Appointment</span></Link></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  );
}

export default Index;
