function Index() {
  return (
    <>
      <section
        className="page-title"
        style={{ backgroundImage: "url(/assets/images/background/bg-3.jpg)" }}
      >
        <div className="auto-container">
          <h1>Frequently Asked Question</h1>
          <div className="text">Treating Customers Like Family Since 2004</div>
        </div>
      </section>

      <section className="faq-section">
        <div className="auto-container">
          <div className="top-content">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="sec-title-new">
                  <div className="sub-title">Frequently Asked Questions</div>
                  <h2>
                    Reliable Answers to Common Questions.
                  </h2>
                </div>
              </div>
              {/* <div className="col-lg-6">
                <div className="text">
                  Lorem ipsum dolor sit amet consectetur adipiscing elit sed do
                  eu smod tempor incidunt labore dolore magna aliqua. Quis ipsum
                  suspen disse ultrices gravida Risus commodo maecenas accumsan.
                </div>
              </div> */}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="text-block">
                <h4>How much does exterminating cost ?</h4>
                <div className="text">
                  Depending on a number of factors, including the type of pest you’re dealing with and the extent of the problem, you can expect to pay between $200 and $400 to exterminate common pests and insects in or around your home.
                </div>
              </div>
            </div>
            </div>

          <div className="faq-accordion" id="accordion">
  <div className="card">
    <div className="card-header" id="headingOne">
      <h5 className="mb-0">
        <button className="btn btn-link" data-toggle="collapse" data-parent="#accordion" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          Termites
        </button>
      </h5>
    </div>

    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne">
      <div className="card-body">
      
                   <div className="row">
            <div className="col-lg-12">
                <ul >
                  <li className="mb-4">
                    <h4>1. What do termites look like?</h4>
                    <div className="text">
                      Termites have soft bodies, pinchers and straight antennae. Colors range from white to light brown in color. Worker termites often appear lighter, while swarming termites are darker.
                    </div>
                  </li>
                  <li className="mb-4">
                  <h4>2. What are signs of a termite swarm?</h4>
                  <div className="text">
                  Termites swarm after their original colony has reached a certain capacity level and is ready to expand. For most colonies, this happens once a year. Hundreds or even thousands of swarmers, called alates, are produced with the sole purpose of reproduction and expansion.
                </div>
                  </li>
                  <li className="mb-4">
                     <h4>3. Do termites have wings?</h4>
                <div className="text">
                  Alates (swarmers) have wings and hard exoskeletons that may be very dark in color. Additionally, flying and swarming termites can be recognized by their prominent wings.
                </div>
                  </li>
                  <li className="mb-4">
                      <h4>4. How big are termites?
</h4>
                <div className="text">
                Termites typically measure between 1/4 and 1/2 of an inch long.

                </div>
                  </li>
                  <li className="mb-4">
                     <h4>5. How to prepare for termite treatment?</h4>
                <div className="text">
                 Check areas around the home for damaged wood, cast-off wings around the floor, and shelter tubes. Call an expert at Orkin for further investigation.
                </div>
                  </li>
                  <li className="mb-4">
                     <h4>6. What does termite damage look like?
</h4>
                <div className="text">
                  Most homeowners don’t notice they have termites until the damage has become costly and tough to fix. Home termite damage is a serious issue. It often ruins the structural integrity of a home. Residents rarely have the chance to inspect hidden areas like wall voids, so the pests tunnel and eat their way through rafters and studs uncontested, leading to collapsed supports.
                </div>
                  </li>
                  <li className="mb-4"> <h4>7. Where do termites live?</h4>
                <div className="text">
                  There are about 50 different kinds of termite species found in North America and each of these species is categorized into one of three termite groups – subterranean, dampwood or drywood. Each termite group has distinctive environmental requirements and behaviors when it comes to whether or not they live in soil and where they construct their colonies.
                </div></li>
                  <li className="mb-4"> <h4>
                   8. How do termites get into your house?
</h4>
                <div className="text">
                  Subterranean termite workers are the caste members that consume wood and they gain entrance into homes by building mud tubes that provide them a moist, protective passageway to get from one location to another. Workers generally gain access to houses by following their underground foraging tunnels, also known as exploratory tubes, to the foundation or underneath the house’s slab.
                </div></li>
                  <li className="mb-4"> <h4>
                  9. How long can termites go without food?
</h4>
                <div className="text">
                  Termites can live only a few days without water, and only two weeks without food.
                </div></li>
                  <li className="mb-4"> <h4>
                  10. Can you get rid of termites completely?
</h4>
                <div className="text">
                  Termites have been around for about 250 million years, making them an active contributor to our environment. In the U.S. alone, there are about 45 active species of termites. In fact, termites live in every U.S. state, except Alaska. While you cannot get rid of termites permanently from the environment, you can help prevent them from taking root in your home and control any active colonies nearby.
                    </div></li>
                  <li className="mb-4">  <h4>
                 11. How do exterminators get rid of termites?
</h4>
                <div className="text">
                  There are a variety of strategies an exterminator can use to get rid of termites. Connect with an Orkin Pro to create an effective termite treatment plan for your home!
                </div></li>
                  <li className="mb-4"> <h4>
                  12. How much does an exterminator cost for termites?
</h4>
                <div className="text">
                  The cost of a termite exterminator varies depending on the pest control treatment plan. Get a free quote and contact your local Orkin Branch Office to request a comprehensive pest inspection from an Orkin Pro.
                </div></li>
                </ul>
                
            </div>
          </div>
      </div>
    </div>
  </div>
  <div className="card">
    <div className="card-header" id="headingTwo">
      <h5 className="mb-0">
        <button className="btn btn-link collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
         Roaches
        </button>
      </h5>
    </div>
    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo">
      <div className="card-body">
        <div className="row">
            <div className="col-lg-12">
                <ul >
                  <li className="mb-4">
                    <h4>1. Is it possible for me to get rid of cockroaches in an apartment if my neighbors have them? </h4>
                    <div className="text">
                     Yes and no. Yes, if your neighbors cooperate and allow all apnrtments to be treated properly. No, if at least one neighbor does nothing and allows the cockroaches to breed
and serves as a reservoir population that may travel via various utility lines and other such routes to infest neighboring units. It is possible, however, to provide reasonable control in some apartments if suitable management methodologies are implemented.
                    </div>
                    <div className="text">
                      What can I do to decrease the number of cockroaches coming from my neighbor? Proofing can be done by stuffing copper wool, caulk, and other such materials into various openings that cockroaches may use as access and entry points. Such openings around utility penetrations, floor moldings, and other such areas should be sealed. Sealers such as caulk, Xcluder, and other such materials may be used. Additionally, it is wise to try to get neighbors to cooperate with the pest-management professionals contracted by the apartment management if such service exists.
                     </div>
                  </li>
                  <li className="mb-4">
                  <h4>2. Is the presence of cockroaches in my place a sign of poor housekeeping?</h4>
                  <div className="text">
                  No. A few cockroaches may be brought in or enter the cleanest of homes undetected at any time. However, their build up and increased population occurs as a result of neglecting the problem.
                </div>
                  </li>
                  <li className="mb-4">
                     <h4>3. Is it ever possible to get rid of cockroaches completely from a home?</h4>
                <div className="text">
                  Yes, and competent professionals do this on a daily basis. However, you may have a re­ infestation if you live in an area where risk of infestation is high due to local conditions. If you're having a cockroach problem, it is wise to rely on the services of experienced pest­ management professionals.
                </div>
                  </li>
                  <li className="mb-4">
                      <h4>4. What are cockroach droppings?
</h4>
                <div className="text">
                Cockroach droppings are their fecal matter. They plaster it on wood or cardboard surfaces, usually in hidden areos where they harbor or hide. In areas where the fecal matter does not stick, it will drop to the nearest surface. The scientific analysis of such fecal matter is called scatology. Each cockroach species produces a uniquely shaped and sized fecal pellet.
                </div>
                  </li>
                  <li className="mb-4">
                     <h4>5. How smart are cockroaches? Do they have brains?</h4>
                <div className="text">
                Cockroaches do not have brains in the normal sense as humans have and understand what a brain is. They have a concentration of nerves in their heads that serve as a focal point for their. nervous system. Cockroaches can learn from experience how to travel a maze. They have enough sense to avoid some pesticide-treated surfaces and move rapidly from extremely hot, dry, or cold areas. This creature has been able to survive for more than three hundred and fifty million years, and despite man's best efforts to eradicate it, it continues to thrive. Biologists view the survival instincts of the cockroach with high regard.
                </div>
                  </li>
                  <li className="mb-4">
                     <h4>6. Can cockroaches eat toothpaste or soap?
</h4>
                <div className="text">
                 Cockroaches will eat both toothpaste and soap. We don't know if they can digest soap.
                We do know mice can consume bar soap, likely for the fat content therein.
                    </div>
                  </li>
                  <li className="mb-4"> <h4>7. Can cockroaches bite me?</h4>
                <div className="text">
                 Cockroaches have chewing mouthparts, so technically speaking, yes, they can bite. However, the damage a cockroach may or may not be able to inflict upon a human through biting alone is minimal due to the size of its mouthparts. A large population of large cockroaches, such as American cockroaches, may be able to do some harm, but this would be an extreme case.
                    </div>
                    <div className="text">
                      In a case where a victim was allegedly abused cockroach biting/chewing was suspected. To determine this we forced exposed, starved American cockroaches to the abdominal skin tissue of a colleague (a forensic entomologist) for about thirty minutes to determine if these cockroaches would bite, feed, and/or cause harm to humans. Not only could he feel the cockroaches biting him, those American cockroaches readily bit, fed, and left scars. In our observation, the scaring appeared as abrasions to the skin, but there was no bleeding. It matched the scars on the abused victim.
                    </div>
                        </li>
                        

                         <li className="mb-4">
                     <h4>8. What Do American Cockroaches Look Like?
</h4>
                <div className="text">
                Adult American cockroaches average between 1.4” to 1.6” (35-41mm) in length, but they can grow to exceed 2”. American cockroaches are reddish-brown in color with a yellow band that outlines the area behind their head. Both males and females have wings and can fly short distances.
                    </div>
                        </li>
                        
                         <li className="mb-4">
                     <h4>9. Do American Cockroaches Bite?
</h4>
                <div className="text">
                Although American cockroaches have the ability to bite, they rarely do. If a bite occurs, it should not be a concern unless it gets infected in which you should seek medical attention.
                    </div>
                  </li>


                </ul>
              </div>
          </div>
      </div>
    </div>
  </div>
  <div className="card">
    <div className="card-header" id="headingThree">
      <h5 className="mb-0">
        <button className="btn btn-link collapsed" data-toggle="collapse"  data-parent="#accordion" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
         Bed Bugs
        </button>
      </h5>
    </div>
    <div id="collapseThree" className="collapse" aria-labelledby="headingThree">
      <div className="card-body">
       
                    <div className="row">
            <div className="col-lg-12">
                <ul >
                  <li className="mb-4">
                    <h4>1. If I leave will bed bugs stays?</h4>
                    <div className="text">
                     Bed bugs can last a long time without feeding. Some references indicate bed bugs
can survive about one year without feeding under ideal conditions. Of course we are dealing with live entities and longevity is based upon local conditions. As such, your mileage may vary.

                    </div>
                  </li>
                  <li className="mb-4">
                  <h4>2. Can bed bugs climb walls?</h4>
                  <div className="text">
                 yes, However generally speaking, bed bugs cannot climb smooth surfaces such as glass and some plastics.
                </div>
                  </li>
                  <li className="mb-4">
                     <h4>3. What do baby bed bugs eat?</h4>
                <div className="text">
              Except for the egg, all stages of bed bu'gs from nymphs to adults feed on blood.
                </div>
                  </li>
                  <li className="mb-4">
                      <h4>4. How big are bed bugs?
</h4>
                <div className="text">
               Bed bug eggs are tiny. Eggs and first-in;t ; nymphs are only about one millimeter long; that's only about <sup>1</sup>&frasl;<sub>32</sub> inch.

                </div>
                  </li>
                  <li className="mb-4">
                     <h4>5. What do bed bug eggs look like?</h4>
                <div className="text">
               Bed bug eggs are a shiny, translucent, milky-white color, as are the newly hatched bed bug nymphs.
                </div>
                  </li>
                   <li className="mb-4">
                     <h4>6. Are all bed bugs red?</h4>
                <div className="text">
                      Immature bed bugs maintain their reddish color for as long as they have remnants of
                      their blood meal within their gut. As the blood meal is digested over time, they
                      become more and more translucent again.

                </div>
                  </li>
                </ul>
                
            </div>
          </div>
      </div>
    </div>
            </div>
            
            <div className="card">
    <div className="card-header" id="headingFour">
      <h5 className="mb-0">
        <button className="btn btn-link collapsed" data-toggle="collapse"  data-parent="#accordion" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
         Rodents
        </button>
      </h5>
    </div>
    <div id="collapseFour" className="collapse" aria-labelledby="headingFour">
      <div className="card-body">
         <div className="row">
            <div className="col-lg-12">
                <ul >
                  <li className="mb-4">
                    <h4>1. The rats aren’t taking the bait on the snap traps. What am I doing wrong?</h4>
                    <div className="text">
                      You may not be doing anything wrong! Rats are neophobic, meaning that they are fearful of new things in their environment. One trick is to bait the trap for several days without setting it. Replace the bait each day, allowing the rats to familiarize themselves with this food source. After a few days, bait and set the trap (arm the spring).
                    </div>
                  </li>
                  <li className="mb-4">
                  <h4>2. What kind of bait should I use on the snap traps?</h4>
                  <div className="text">
                  Roof rats and Norway rats have similar food preferences, and there are lots of good bait choices. Peanut butter, bacon, chewy candy bars and moistened oatmeal are just a few examples.
                </div>
                  </li>
                  <li className="mb-4">
                     <h4>3. Do the ultrasonic devices that you plug into the wall work to repel rats?</h4>
                <div className="text">
                 We wish that rodent prevention was as easy as plugging a device into a wall and walking away. Sadly, there is not enough data to suggest that these devices are an effective way to repel rats.
                </div>
                  </li>
                  <li className="mb-4">
                      <h4>4. What type of trap works best, snap traps or electric traps?
</h4>
                <div className="text">
               Electronic traps and snaps traps are both effective for trapping rats and mice. Place them where the rodents are most active (e.g. near entry holes, along walls, in dark areas, behind appliances or where you see droppings).

                </div>
                  </li>
                  {/* <li className="mb-4">
                     <h4>5. Can I trap rats and relocate them?</h4>
                <div className="text">
                No. In California it is illegal to trap and relocate animals. If you trap a live rat on your property, you must either euthanize it at once or release it on the site where it was trapped.
                </div>
                        </li> */}
                        
                         <li className="mb-4">
                     <h4>5. What is the difference between a rat and a mouse?</h4>
                <div className="text">
                Mice and rats are both rodents, A Rat is larger and heavier than a mouse. Adult rats are generally about 8 to 10 inches in length, including their tail, and weigh around 8 to 12 ounces. In contrast, adult mice are generally about 5 to 7.
                </div>
                  </li>
                </ul>
                
            </div>
          </div>
                
      </div>
    </div>
            </div>
            
              <div className="card">
    <div className="card-header" id="headingFive">
      <h5 className="mb-0">
        <button className="btn btn-link collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
         Mice
        </button>
      </h5>
    </div>
    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" >
      <div className="card-body">
          <div className="row">
            <div className="col-lg-12">
                <ul >
                  <li className="mb-4">
                    <h4>1. How Do You Know If You Have Mice?</h4>
                    <div className="text">
                     Although the most obvious sign is actually seeing live or dead mice in your home, there are plenty of other signs that can tell you a mouse infestation may be building. These include:
                    </div>
                    <div className="text about-us-section pt-3 pb-0"><ul className="list">
                      <li>Gnawed holes in stored foods, piled papers, insulation, etc.</li>
                      <li>Food scraps or wrappings left behind, especially in out-of-the-way places, such as inside shoes or boots or in the corners of closets and cabinets.</li>
                      <li>Droppings or tiny hairs.</li>
                      <li>Runways—narrow pathways where dust and dirt have been swept clean, noticeable grease marks, or urine trails can be seen under a black light.</li>
                      <li>Nests or piled nesting materials.</li>
                      <li>Skittering or scratching sounds coming from wall, ceiling, or floor cavities.</li>
                      <li>Stale, rank, or musty odors.</li>
                    </ul></div>
                  </li>
                  <li className="mb-4">
                  <h4>2. How Do You Know If It’s a Mouse or a Rat?</h4>
                  <div className="text">
                  There are key differences between mice and rats. Mice are much smaller than rats. Adult mice are about 7 <sup>1</sup>&frasl;<sub>2</sub> inches in length, including the tail. The most common rats in the United States are the Norway rat and the roof rat. They are about 14 to 16 inches in length, with tail length varying by species.
                </div>
                  </li>
                  <li className="mb-4">
                     <h4>3. What Do Mice Eat?</h4>
                <div className="text">
                  Mice prefer to eat cereal grains and plants, but they will feed on almost anything. They are very commonly drawn to dried (and bagged) stored food, including pet food.
                </div>
                  </li>
                  <li className="mb-4">
                      <h4>4. How Long Do Mice Live?
</h4>
                <div className="text">
                A house mouse will rarely live more than a year in the wild, but in a protected environment with food and water (such as a home), it can live up to three years.

                </div>
                  </li>
                  <li className="mb-4">
                     <h4>5. Do You Keep Finding Shredded Paper and Black Rice-Like Things?</h4>
                <div className="text">
                 If you encounter shredded paper, you have likely found a mouse nest. A mouse will build its nest from just about any soft material or finely shredded paper. And the little black “rice” is most likely mouse droppings.
                </div>
                  </li>
                  <li className="mb-4">
                     <h4>6. Where Should You Put Mouse Traps?
</h4>
                <div className="text">
                 Mouse traps should be placed where the signs of mice have been found—shredded paper or cloth, droppings, urine stains, and gnawed items. Traps are available from home and garden stores or even some grocery stores. 
                </div>
                  </li>
                  <li className="mb-4"> <h4>7. What If Your Traps Aren’t Working?</h4>
                <div className="text">
                  Mice are very curious and will investigate new things. So, if they are not caught within the first days of trap placement, the trap (or bait) is probably in the wrong place and should be moved. Be sure to place the trap where any signs of mice are seen or where food or water is available.
                </div></li>
                  <li className="mb-4"> <h4>
                   8. What Is the Best Bait for Mouse Traps?
</h4>
                <div className="text">
                  Contrary to popular belief, cheese is not the best bait to use in traps. Mice are more attracted to peanut butter, but it needs to be replaced if it gets too dry or hard. Mice are also attracted to bacon, nuts, dried food, and sticky candies. The bait should be securely attached to the trap trigger so the rodent can’t simply pluck it off and walk away. Often, a small amount of peanut butter worked into the crevices or cup of a trap is the most effective bait. Mice are attracted to the smell and will have to work to get the food out of the bait, setting off the trap.
                </div></li>
                  <li className="mb-4"> <h4>
                  9. Why Are You Not Seeing the Mice?
</h4>
                <div className="text">
                  Mice are nocturnal creatures, so they are most active between dusk and dawn. They don’t usually like bright lights, but a mouse may sometimes be seen during the day, especially if its nest has been disturbed or it is seeking food. Seeing them in the day also can indicate a large infestation in a home.
                </div></li>
                  <li className="mb-4"> <h4>
                  10. How Quickly Do Mice Breed?
</h4>
                <div className="text">
                 In a single year, one female mouse can breed up to 10 litters of five to six young. These 60 offspring can begin to reproduce themselves in as little as six weeks. So within months, you could have a huge population of mice.
                    </div></li>
                  <li className="mb-4">  <h4>
                 11. How Do Mice Get Inside a Home?
</h4>
                <div className="text">
                 A mouse can slip through holes and gaps as small as <sup>1</sup>&frasl;<sub>4</sub> inch, or roughly the size of a pencil. And if an opening is not big enough to squeeze through, the mouse can gnaw it until it is big enough. Mice also can jump 13 inches high and can run along wires, cables, and ropes. They are excellent jumpers, swimmers, and climbers and can scale rough and vertical surfaces.
                </div></li>
                  <li className="mb-4"> <h4>
                  12. What Can You Do to Get Rid of Mice in Your Home?
</h4>
                <div className="text">
                 There are a number of methods of control, including traps, baits, rodenticides, and Hilton Head Exterminators Rodent Exclusion Department. It’s also a good idea to seal openings around pipes, roof vents, and other potential entry points.
                          </div></li>
                        <li className="mb-4">
                     <h4>13. What is the difference between a rat and a mouse?</h4>
                <div className="text">
                Mice and rats are both rodents, A Rat is larger and heavier than a mouse. Adult rats are generally about 8 to 10 inches in length, including their tail, and weigh around 8 to 12 ounces. In contrast, adult mice are generally about 5 to 7.
                </div>
                  </li>
                </ul>
            </div>
          </div>
                
      </div>
    </div>
  </div>
</div>





           
         
          
          






          
        
        </div>
      </section>
    </>
  );
}

export default Index;
