import { Link } from "react-router-dom";

function Index() {
  return (
    <>
        <section className="about-us-section-two">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="content-block wow fadeInUp"
                data-wow-duration="1500ms"
              >
                <div className="sec-title mb-20">
                  <div className="sub-title">About Sani Tech Pest Inc.</div>
                  <h2>
                     
                    Simply the Best In <br />Pest Control
                  </h2>
                </div>
                <div className="text">
                  SANI-TECh PEST INC. is a full service I.P.M pest control <br />
                  company located in the BRONX providing service to all 5 <br />
                  boroughs for over 20 yrs. Our services are designed to fit the <br /> 
                  needs of our customers. We provide green services as well <br />
                  as pesticide applications Rodent Proofing, Roaches, Mice, Flies, Bees, <br />
                  Rats and more. <br />
                  All of our service technicians are licensed by the department  <br />
                  of environmental conservation we are fully insured.
                </div>
                <div className="link-btn">
                  <Link to="/services" className="theme-btn btn-style-one">
                    <span>Find Services</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="image-wrapper wow fadeInDown"
                data-wow-duration="1500ms"
              >
                {/* <span className="shape-one">
                  <img src="/assets/images/shape/shape-6.png" alt="" />
                </span> */}
                {/* <span className="shape-two">
                  <img src="/assets/images/shape/shape-7.png" alt="" />
                </span> */}
                <div className="image">
                  <div
                    className="bg"
                    style={{
                      backgroundImage:
                        "url(/assets/images/resource/image-9.jpg)",
                    }}
                  ></div>
                  <img src="/assets/images/resource/image-2.png" alt="" />
                </div>
                {/* <div className="offer" data-parallax='{"x": 50}'>
                  20{" "}
                  <h4>
                    % <br /> <span>OFF</span>
                  </h4>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
