function Index() {
  return (
    <>
        <section className="working-process-section">
        <div className="auto-container">
            <div className="sec-title text-center">
                <div className="sub-title">Process</div>
                <h2>Working Process</h2>
            </div>
            <div className="wrapper-box">
                <div className="step"><img src="/assets/images/resource/step.png" alt="" /></div>
                <div className="row">
                    <div className="col-lg-4 process-block">
                        <div className="inner-box wow fadeInDown" data-wow-duration="1500ms">
                            <div className="image"><img src="/assets/images/resource/image-5.jpg" alt="" /></div>
                                  <div className="count">1</div>
                            <h4>Call & Have Your Inspection Done</h4>
                        </div>
                    </div>
                    <div className="col-lg-4 process-block">
                        <div className="inner-box wow fadeInUp" data-wow-duration="1500ms">
                            <div className="image"><img src="assets/images/resource/image-6.jpg" alt="" /></div>
                            <div className="count">2</div>
                                  <h4>
                                      Receive Your Services
</h4>
                        </div>
                    </div>
                    <div className="col-lg-4 process-block">
                        <div className="inner-box wow fadeInDown" data-wow-duration="1500ms">
                            <div className="image"><img src="assets/images/resource/image-7.jpg" alt="" /></div>
                            <div className="count">3</div>
                            <h4>Courtesy 2 Week Follow Up </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  );
}

export default Index;
