import ServiceBanner from "../../../component/ServiceBanner";
import ServiceSidebar from "../../../component/ServiceSidebar";

function Index() {
  return (
    <>
    <ServiceBanner title="American Roach (water Bugs) Control" image="/assets/images/background/american-roaches.jpg" />
    <section className="service-details">
        <div className="auto-container">
            <div className="row">
                <div className="col-lg-8 order-lg-2">
                    <div className="context-block">
                        <h2>American Roach (water Bugs) Control</h2>
                        <div className="text">
                                  <p>
                                    Our american roach control service begins with the identification of moisture and food sources and includes but is not limited to the treatment of void areas with dusts, granular baits, residual pesticide treatments, and the sealing of gaps and openings around water lines, electrical lines and perimeter areas.
                                  </p>
                            <p>The American cockroach is the largest of the house-infesting roaches and a major pest in the United States. It’s also commonly known as the water bug, the Bombay canary or the palmetto bug. Despite its name, the American cockroach is not native to North America and some evidence suggested that American cockroaches were introduced via ships from Africa in the early 1600s.</p>
                              </div>
                        {/* <div className="image-block">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="image mb-30"><img src="/assets/images/resource/image-10.jpg" alt="" /></div>
                                </div>
                                <div className="col-md-6">
                                    <div className="image mb-30"><img src="/assets/images/resource/image-11.jpg" alt="" /></div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                      <div className="col-lg-4 sidebar-side left-sidebar">
                          <ServiceSidebar link="american-roaches" />
                </div>
            </div>
        </div>
    </section>

    </>
  );
}

export default Index;
