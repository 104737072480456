import ServiceBanner from "../../../component/ServiceBanner";
import ServiceSidebar from "../../../component/ServiceSidebar";

function Index() {
  return (
    <>
   <ServiceBanner title="Rat Control" image="/assets/images/background/rat.jpg" />
    <section className="service-details">
        <div className="auto-container">
            <div className="row">
                <div className="col-lg-8 order-lg-2">
                    <div className="context-block">
                        <h2>We Provide Rat Control Services</h2>
                              <div className="text">
                                  <p>Our rat service includes but is not limited to exclusion , baiting, trapping, the treating of burrows and the identification of food and harborage sites in and around the property.</p>
                                  <p>The Norway rat is the largest of the commensal (living in close association with humans) rodents. The head and body are 7 to 10 inches long and the tail is an additional 6 to 8 inches. It has a stocky body and weighs 7 to 18 ounces. The fur is coarse, shaggy, and brown with some black hairs. The muzzle is blunt, eyes and ears are small, and the tail, which is bi-colored, is_shorterthan the head and body combined. Norway rat droppings are up to <sup>3</sup>&frasl;<sub>4</sub> inch long with blunt ends.</p>
                                  {/* <p>Adults are sexually mature in two to five months. Females produce three to six litters per year, each averaging seven to eight young. Adults live from six to 12 months. They have poor sight, but keen senses of smell, taste, hearing, and touch.</p> */}
                        </div>
                        {/* <div className="image-block">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="image mb-30"><img src="/assets/images/resource/image-10.jpg" alt="" /></div>
                                </div>
                                <div className="col-md-6">
                                    <div className="image mb-30"><img src="/assets/images/resource/image-11.jpg" alt="" /></div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="col-lg-4 sidebar-side left-sidebar">
                   <ServiceSidebar link="rat" />
                </div>
            </div>
        </div>
    </section>
    </>
  );
}

export default Index;
