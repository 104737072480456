import { Outlet, useLocation } from "react-router-dom";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { useLayoutEffect } from "react";

function Index() {
  //   const navigation = useNavigation();
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
    }, [location.pathname]);
    return children;
  };

  return (
    <Wrapper>
      <Header />
      <Outlet />
      <Footer />
    </Wrapper>
  );
}

export default Index;
