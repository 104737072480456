import { useEffect } from "react";
import Swiper from "swiper";
import { Link } from "react-router-dom";

function Index() {

    useEffect(() => {
         var bannerSlider = new Swiper(".banner-slider", {
        preloadImages: false,
        loop: true,
        grabCursor: true,
        centeredSlides: false,
        resistance: true,
        resistanceRatio: 0.6,
        speed: 1400,
        spaceBetween: 0,
        parallax: false,
        effect: "fade",
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".banner-slider-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".banner-slider-button-next",
          prevEl: ".banner-slider-button-prev",
        },
      });
  }, []);
  return (
      <>
         
     <section className="banner-section style-three">
        <div className="swiper-container banner-slider">
          <div className="swiper-wrapper">
            <div
              className="swiper-slide"
              style={{
                backgroundImage: "url(/assets/images/main-slider/image-4.webp)",
              }}
            >
              <div className="content-outer">
                <div className="content-box justify-content-center">
                  <div className="inner text-center">
                    <h1>
                      Serving Commercial & Residential Properties <br />
                      Within The Five Boroughs Of New York City.
                    </h1>
                    <div className="text">
                      Experience the difference and contact us now.
                    </div>
                    <div className="link-box">
                      <Link to="/appointment" className="theme-btn btn-style-one">
                        <span>Schedule Services</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="swiper-slide"
              style={{
                backgroundImage: "url(/assets/images/main-slider/image-5.webp)",
              }}
            >
              <div className="content-outer">
                <div className="content-box justify-content-center">
                  <div className="inner text-center">
                    <h1>
                      We are here to keep your  <br /> home pest free
                      
                    </h1>
                    <div className="text">
                      Experience the difference and contact us now.
                    </div>
                    <div className="link-box">
                      <Link to="/appointment" className="theme-btn btn-style-one">
                        <span>Schedule Services</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="swiper-slide"
              style={{
                backgroundImage: "url(/assets/images/main-slider/image-6.webp)",
              }}
            >
              <div className="content-outer">
                <div className="content-box justify-content-center">
                  <div className="inner text-center">
                    <h1>
                      Complete Pest Control Services 
                      <br /> Bronx New York
                    </h1>
                    <div className="text">
                      Experience the difference and contact us now.
                    </div>
                    <div className="link-box">
                      <Link to="/appointment" className="theme-btn btn-style-one">
                        <span>Schedule Services</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-slider-nav">
          <div className="banner-slider-control banner-slider-button-prev">
            <span>
              <i className="flaticon-left-arrow"></i>
            </span>
          </div>
          <div className="banner-slider-control banner-slider-button-next">
            <span>
              <i className="flaticon-left-arrow"></i>
            </span>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
