// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** http Imports
import http from "../../common/http.service";

// ** fetch Product
export const fetchProduct = createAsyncThunk(
  "product/fetchProduct",
  async (payload) => {
    try {
      const response = await http.get("/product" + payload);
      return response.data;
    } catch (error) {
      throw new Error(error?.message);
    }
  }
);

export const productSlice = createSlice({
  name: "product",
  initialState: {
    list: [],
    isLoader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProduct.pending, (state, action) => {
      state.isLoader = true;
    });
    builder.addCase(fetchProduct.fulfilled, (state, action) => {
      state.list = action.payload?.data;
      state.isLoader = false;
    });
    builder.addCase(fetchProduct.rejected, (state, action) => {
      state.isLoader = false;
    });
  },
});

export default productSlice.reducer;
