import Banner from "../../component/Banner";
import ContactInfo from "../../component/ContactInfo";
import About from "../../component/About";
import Process from "../../component/Process";
import BookAppointment from "../../component/BookAppointment";
import Card from "../../component/Card";
import FunFacts from "../../component/FunFacts";
import Residential from "../../component/Residential";
import Commercial from "../../component/Commercial";
import WhyChoose from "../../component/WhyChoose";

function Index() {
  return (
    <>
     <Banner />
      <ContactInfo />
       <About />
    <section className="services-section style-three">
        <div className="auto-container">
          <div className="sec-title text-center">
            <div className="sub-title">Services</div>
            <h2>Common Pest Control</h2>
          </div>
          <div className="wrapper-box">
            <span className="shape-three">
              <img src="/assets/images/shape/shape-6.png" alt="" />
            </span>
            <span className="shape-four">
              <img src="/assets/images/shape/shape-7.png" alt="" />
            </span>
            <div className="row">
               <Card title="German Roach" iconName="flaticon-cockroach" text="" link="/services/german-roaches" />
              <Card title="American Roach" iconName="flaticon-cockroach" text="" link="/services/american-roaches" />
              <Card title="Ant" iconName="flaticon-ant" text="" link="/services/ants" />
              <Card title="Bedbugs" iconName="flaticon-mosquito" text="" link="/services/bed-bugs" />
              <Card title="Mice" iconName="flaticon-squirrel" text="" link="/services/mice" />
              <Card title="Rat" iconName="flaticon-squirrel" text="" link="/services/rat" />
              <Card title="Fly" iconName="flaticon-fly" text="" link="/services/fly" />
              <Card title="WASP" iconName="flaticon-bee" text="" link="/services/bees" />
            </div>
          </div>
        </div>
      </section>
      <Process />
       <Residential />
      <Commercial />
     <FunFacts />
    <WhyChoose />
        <BookAppointment /> 
    </>
  );
}

export default Index;
