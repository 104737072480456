function Index() {
  return (
    <>
      <section className="about-us-section about-us-section-pest">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="image-wrapper wow fadeInDown"
                data-wow-duration="1500ms"
              >
                <div className="image">
                  <img src="/assets/images/resource/residential-inset2.jpg" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="content-block wow fadeInUp"
                data-wow-duration="1500ms"
              >
                <div className="sec-title mb-20">
                  <h2>
                   Residential Services
                  </h2>
                </div>
                {/* <div className="text">
                  Lorem ipsum dolor sit amet consectetur adipiscing elit sed do
                  eiu smod tempor incididunt ut labore dolore magna aliqua.Quis
                  ipsum suspen disse ultrices gravida Risus commodo viverra
                  maecenas accumsan lacus vel facilisis.{" "}
                </div> */}
                <ul className="list">
                  <li>Mice & Rats</li>
                  <li>Rodent proofing</li>
                  <li>Baiting & Trapping</li>
                  <li>Insects</li>
                   <li>Vacuuming, Flushing, Baiting </li>
                    <li>Steam and Freezing for bed bugs insecticide Applications</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
