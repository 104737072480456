import { Link } from "react-router-dom";

function Index({ title,iconName, text, link }) {
  return (
      <>
           <div className="col-lg-3 col-md-6 service-block-one">
                <div
                  className="inner-box wow fadeInUp"
                  data-wow-duration="1500ms"
                >
                  <div className="icon">
                    <span className={iconName}></span>
                  </div>
                  <h4>
                      <Link to={link}>{title }</Link>
                  </h4>
                  <div className="text">
                    {text}
                  </div>
                  <div className="link-btn">
                    <Link
                      to={link}
                      className="theme-btn btn-style-one style-two"
                    >
                      <span>Learn More</span>
                    </Link>
                  </div>
                </div>
              </div>
    </>
  );
}

export default Index;
