function Index() {
  return (
    <>
      <section className="whychoose-us-section-three">
        <div className="auto-container">
          <div className="row no-gutters">
            <div className="col-lg-6 video-column">
              <div
                className="video-box wow fadeInUp"
                data-wow-duration="1500ms"
                style={{
                  backgroundImage: "url(/assets/images/resource/image-1.png)",
                }}
              >
                
                <div className="image">
                  <img src="/assets/images/resource/image-1.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="inner-container">
                <div className="content-block">
                  <div className="shape">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="769"
                      height="669"
                    >
                      <defs>
                        <filter
                          filterUnits="userSpaceOnUse"
                          id="a"
                          x="0"
                          y="0"
                          width="769"
                          height="669"
                        >
                          <feOffset in="SourceAlpha" dx="24.073" dy="31.945" />
                          <feGaussianBlur
                            result="blurOut"
                            stdDeviation="30.746"
                          />
                          <feFlood floodColor="#253B70" result="floodOut" />
                          <feComposite
                            operator="atop"
                            in="floodOut"
                            in2="blurOut"
                          />
                          <feComponentTransfer>
                            <feFuncA type="linear" slope=".04" />
                          </feComponentTransfer>
                          <feMerge>
                            <feMergeNode />
                            <feMergeNode in="SourceGraphic" />
                          </feMerge>
                        </filter>
                      </defs>
                      <g filter="url(#a)">
                        <path
                          fillRule="evenodd"
                          fill="#FFF"
                          d="M65 26.999h590c16.569 0 30 13.432 30 30v490c0 16.569-13.431 30-30 30l-560-30c-16.569 0-30-13.432-30-30l-30-460c0-16.568 13.431-30 30-30z"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="sec-title mb-30">
                    <div className="sub-title">Why Choose Us</div>
                    <h2>
                      We Treat Your Home  <br />
Like We Treat Ours!

                    </h2>
                  </div>
                  <div className="pricing-block">
                   <div className="content mb-0">
                            <ul>
                                 <li>We Have 20 Years Of Experience In Pest Control</li>
                                <li>We Offer High-Quality Pest Control Services</li>
                                <li>Service commercial & residential properties</li>
                                <li>Fully licensed and insured technicians</li>
                                <li>Courteous service and effective solutions</li>
                                    </ul>   
                    </div>  
                    </div>
                  {/* <div
                    className="icon-box wow fadeInUp"
                    data-wow-duration="1500ms"
                  >
                    <div className="icon">
                      <span className="flaticon-settings"></span>
                    </div>
                    <div className="content">
                      <h4>Top Rated Services</h4>
                      <div className="text">
                        Lorem ipsum dolor sit amet consect. <br /> elit sed do
                        smod.
                      </div>
                    </div>
                  </div>
                  <div
                    className="icon-box wow fadeInUp"
                    data-wow-duration="1500ms"
                  >
                    <div className="icon">
                      <span className="flaticon-pesticide"></span>
                    </div>
                    <div className="content">
                      <h4>Fully Equipmented</h4>
                      <div className="text">
                        Lorem ipsum dolor sit amet consect. <br /> elit sed do
                        smod.
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
