import React, { useEffect, useState } from "react";

function Index({ paginate, page, setPage }) {
  const [end, setEnd] = useState("");
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(paginate?.totalDocs / paginate?.limit); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    var end = paginate?.totalDocs;
    if (paginate?.limit < paginate?.totalDocs) {
      end = paginate?.limit * page;
      if (end > paginate?.totalDocs) {
        end = paginate?.totalDocs;
      }
    }
    setEnd(end);
  }, [paginate, page]);

  const onPageChange = (page) => (event) => {
    event.preventDefault();
    setPage(page);
  };

  return (
    <>
       <div className="page-pagination mt-40 text-center">
                <ul className="clearfix">
          <li  style={{cursor:'pointer', display :  paginate?.hasPrevPage === false ? "none" : ""}}
             ><a  onClick={() => setPage(Number(page) - 1)}>Prev</a></li>
          
          {pageNumbers.map((number) => {
            return (
                   <li  key={number} className={`page-numbers ${
                      page == number ? "active" : ""
                    } `}  style={{ cursor: 'pointer' }}><a onClick={onPageChange(number)}>  {number}</a></li>
                );
              })}
                    <li   style={{cursor:'pointer' ,display :  paginate?.hasNextPage === false ? "none" : ""}}><a onClick={() => setPage(Number(page) + 1)}  >Next</a></li>
                </ul> 
      </div>
    </>
  );
}

export default Index;
