function Index() {
  return (
    <>
      <section className="about-us-section about-us-section-pest">
        <div className="auto-container">
          <div className="row">
           
            <div className="col-lg-6">
              <div
                className="content-block wow fadeInUp"
                data-wow-duration="1500ms"
              >
                <div className="sec-title mb-20">
                  <h2>
                    Commercial Services
                  </h2>
                </div>
                <ul className="list">
                  <li>Violation Removal</li>
                  <li>Restaurant Violation Removal</li>
                  <li>Roaches</li>
                  <li>Mice</li>
                  <li>Rats</li>
                  <li>Flies</li>
                  <li>We employ integrated pest management processes to eliminate pest</li>
                  <li>In all commercial establishments</li>
                </ul>
              </div>
            </div>

             <div className="col-lg-6">
              <div
                className="image-wrapper wow fadeInDown"
                data-wow-duration="1500ms"
              >
                <div className="image">
                  <img src="/assets/images/resource/commercial-inset3.jpg" alt="" />
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
