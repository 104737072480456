import ServiceBanner from "../../../component/ServiceBanner";
import ServiceSidebar from "../../../component/ServiceSidebar";

function Index() {
  return (
    <>
    <ServiceBanner title="Fly Control" image="/assets/images/background/fly.jpg" />
    <section className="service-details">
        <div className="auto-container">
            <div className="row">
                <div className="col-lg-8 order-lg-2">
                    <div className="context-block">
                        <h2>Fly Control</h2>
                              <div className="text">
                                  <p>Our fly services consist of the removal of breeding sites  when possible, sanitizing drains with bio foams, the treating of landing sites and trapping.</p>
                                  <p>
                                    Like all pests, flies are attracted to a good source of food, shelter, and a quality breeding ground. However, different species of flies have different needs and are therefore attracted to different things inside or around your home.
                            </p>
                                  <p><b>Common house flies:</b> These dark gray, slightly hairy flying pests have six legs and a small oval body measuring around 0.19” (5 mm) long.
                                  </p>
                            <p><b>Drain flies:</b> These winged bugs are gray, with a fuzzy appearance making them look like tiny moths. As the name suggests, they typically live in drain slurry.</p>
                            <p><b>Fungus gnats:</b> A common house fly species with a slender body measuring 0.11” (3 mm) long. Often mistaken for fruit flies, fungus gnats live in houseplant soil.</p>
                        </div>
                        {/* <div className="image-block">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="image mb-30"><img src="/assets/images/resource/image-10.jpg" alt="" /></div>
                                </div>
                                <div className="col-md-6">
                                    <div className="image mb-30"><img src="/assets/images/resource/image-11.jpg" alt="" /></div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="col-lg-4 sidebar-side left-sidebar">
                   <ServiceSidebar link="fly" />
                </div>
            </div>
        </div>
    </section>
    </>
  );
}

export default Index;
