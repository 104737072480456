import ServiceBanner from "../../../component/ServiceBanner";
import ServiceSidebar from "../../../component/ServiceSidebar";

function Index() {
  return (
    <>
     <ServiceBanner title="Bedbugs Control" image="/assets/images/background/bed-bugs.jpg" />
    <section className="service-details">
        <div className="auto-container">
            <div className="row">
                <div className="col-lg-8 order-lg-2">
                    <div className="context-block">
                        <h2>Bedbugs Control</h2>
                              <div className="text">
                                  <p>Our bedbug service consists of three visits, one time per week. We utilize commercial steam machines, vacuum cleaners, cryonite ( freezing),  residual chemicals, dusts, monitors, hot houses & more.</p>
                            <p>The adult bed bug is <sup>3</sup>&frasl;<sub>16</sub> inch long and ovate; coloratio,. is rusty-red or mahogany. The bed bug is flat and thin when unf, but becomes more elongate, plump, and red when it is engorged with blood. Four-segmented antennae are attached to the head between the prominent compound eyes. The three-segmented beak, or proboscis, is located beneath the head and passes back between the front legs/ The bed bug cannot fly as its wings are reduced to short wing pads.</p>
                       <p>The female bed bug lays one to five eggs per day and 200 to 500 within her lifetime. She uses a clear substance to attach them in cracks and on rough surfaces. Under ideal conditions, eggs hatch in about seven days, and the nymphs molt five times, taking a blood meal, between each molt. Development time from egg to adult is as little as· 21 days. The adult can live for almost one year.</p>
                              </div>
                        {/* <div className="image-block">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="image mb-30"><img src="/assets/images/resource/image-10.jpg" alt="" /></div>
                                </div>
                                <div className="col-md-6">
                                    <div className="image mb-30"><img src="/assets/images/resource/image-11.jpg" alt="" /></div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="col-lg-4 sidebar-side left-sidebar">
                    <ServiceSidebar link="bed-bugs" />
                </div>
            </div>
        </div>
    </section>
    </>
  );
}

export default Index;
