import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json",
  },
  transformResponse: axios.defaults.transformResponse.concat((resp) => {
    // this should now be JSON
    if (!resp?.status) {
      throw new Error(resp?.message);
    }
    return resp;
  }),
});

export default api;
