import { Link } from "react-router-dom";

function Index() {
  return (
    <>
      <section
        className="page-title"
        style={{ backgroundImage: "url(/assets/images/background/bg-3.jpg)" }}
      >
        <div className="auto-container">
          <h1>404 Page</h1>
          <div className="text">Treating Customers Like Family Since 2004</div>
        </div>
      </section>

      <section className="error-section">
        <div className="auto-container">
          <div className="content">
            <h1>404</h1>
            <h2>Oops! That page can’t be found</h2>
            <div className="text">
              Sorry, but the page you are looking for does not existing
            </div>
            <Link to="/" className="theme-btn btn-style-one">
              <span> Go to home page</span>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
