
function Index({title , image ='/assets/images/background/bg-4.jpg'}) {
  return (
    <>
      
      <div
              className="service-section"
              style={{backgroundImage: `url(${image})`}}
            >
              <div className="content-outer page-title style-two">
                <div className="auto-container">
          <h1>{title}</h1>
            <div className="text">Treating Customers Like Family Since 2004</div>
        </div>
              </div>
            </div>
    {/* <section className="page-title style-two" style={{backgroundImage: `url(${image})`}}>
        <div className="auto-container">
          <h1>{title}</h1>
            <div className="text">Treating Customers Like Family Since 2004</div>
        </div>
    </section> */}
    </>
  );
}

export default Index;
