import { Link,NavLink } from "react-router-dom";

function Index() {
  return (
    <>
      <div className="auto-container">
            <div className="inner-container">
              <div className="logo-box">
                <div className="logo">
                  <Link to={'/'}>
                    <img src="/assets/images/logo.png" alt="" />
                  </Link>
                </div>
              </div>
              <div className="nav-outer">
                <div className="mobile-nav-toggler">
                  <img src="/assets/images/icons/icon-bar.png" alt="" />
                </div>

                <nav className="main-menu navbar-expand-md navbar-light">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation">
                      <li >
                         <NavLink to="/" exact>Home</NavLink>
                      </li>
                      <li className="dropdown">
                         <NavLink to="/services" exact>Pest Services</NavLink>
                        <ul>
                          <li>
                            <NavLink to="/services/german-roaches">German Roach Control</NavLink>
                          </li>
                          <li >
                            <NavLink to="/services/american-roaches">American Roach Control</NavLink>
                          </li>
                          <li>
                            <NavLink to="/services/ants">Ant Control</NavLink>
                          </li>
                          <li>
                            <NavLink to="/services/bed-bugs">Bedbugs Control</NavLink>
                          </li>
                          <li>
                            <NavLink to="/services/mice">Mice Control</NavLink>
                          </li>
                          <li>
                            <NavLink to="/services/rat">Rat Control</NavLink>
                          </li>
                          <li>
                            <NavLink to="/services/fly">Fly Control</NavLink>
                          </li>
                           <li>
                            <NavLink to="/services/bees">WASP Control</NavLink>
                          </li>
                            {/* <li>
                             <NavLink to="/services">All Services</NavLink>
                          </li> */}
                        </ul>
                      </li>
                      {/* <li className="dropdown">
                        <a href="projects.html">Pages</a>
                        <ul>
                          <li>
                            <a href="gallery.html">Gallery </a>
                          </li>
                          <li>
                            <a href="pricing.html">Pricing</a>
                          </li>
                          <li>
                            <a href="team.html">Team</a>
                          </li>
                          <li>
                            <a href="faq.html">FAQ</a>
                          </li>
                          <li>
                            <a href="404.html">404 Page</a>
                          </li>
                        </ul>
                      </li> */}
                 
                     <li >
                         <NavLink to="/products" exact>Products</NavLink>
                  </li>
                   <li>
                        <NavLink to="/faqs" exact>FAQ</NavLink>
                      </li>
                      <li>
                        <NavLink to="/contact-us" exact>Contact Us</NavLink>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="link-btn">
            <NavLink to="/appointment" className="theme-btn btn-style-one">
              <span>Appointment</span>
                  </NavLink>
              </div>
            </div>
          </div>
   
    </>
  );
}

export default Index;
