import ServiceBanner from "../../../component/ServiceBanner";
import ServiceSidebar from "../../../component/ServiceSidebar";

function Index() {
  return (
    <>
    <ServiceBanner title="Mice Control" image="/assets/images/background/mice.jpg" />
    <section className="service-details">
        <div className="auto-container">
            <div className="row">
                <div className="col-lg-8 order-lg-2">
                    <div className="context-block">
                        <h2>Mice Control</h2>
                              <div className="text">
                                  <p>Our mouse service begins with a detailed inspection of the location to identify conditions conducive to mice infestations, we examine all openings a quarter of an inch or more,  we then at the time of service treat and seal all holes and place bait boxes and monitors.</p>
                                  <p>The house mouse is the most common and economically important commensal (living in close association with humans) rodent. The house mouse is gray and weighs <sup>1</sup>&frasl;<sub>2</sub> to one ounce. Its body is three to four inches long and the tail three to four inches long. The muzzle is pointed,_lb_e ears are large, the eyes and body are small. Typically, house mice are slightly smaller than deer mice. Adult droppings are <sup>1</sup>&frasl;<sub>8</sub> to <sup>1</sup>&frasl;<sub>4</sub> inch long and rod-shaped with pointed ends.</p>
                            {/* <p>The female house mouse reaches sexual maturity in 35 days and-averages eight litters per year, each of which averages six young. Thus, with 30 to 35 weaned mice per year, populations build up rapidly. The mice typically produce their largest litters in the spring, depending on climatic conditions, and begin to breed at five to six weeks of age. The life span is one year.</p> */}
                        </div>
                        {/* <div className="image-block">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="image mb-30"><img src="/assets/images/resource/image-10.jpg" alt="" /></div>
                                </div>
                                <div className="col-md-6">
                                    <div className="image mb-30"><img src="/assets/images/resource/image-11.jpg" alt="" /></div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="col-lg-4 sidebar-side left-sidebar">
                   <ServiceSidebar link="mice" />
                </div>
            </div>
        </div>
    </section>
    </>
  );
}

export default Index;
