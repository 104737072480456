import { Link } from "react-router-dom";

function Index() {
  return (
    <>
      <section
        className="page-title"
        style={{ backgroundImage: "url(/assets/images/background/bg-3.jpg)" }}
      >
        <div className="auto-container">
          <h1>About Us</h1>
          <div className="text">Treating Customers Like Family Since 2004</div>
        </div>
      </section>
      <section className="about-us-section">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="image-wrapper wow fadeInDown"
                data-wow-duration="1500ms"
              >
                <div className="image">
                  <img src="/assets/images/resource/image-1.jpg" alt="" />
                </div>
                <div className="years-of-experience" data-parallax='{"y": 50}'>
                  <svg width="349px" height="359px">
                    <defs>
                      <filter
                        filterUnits="userSpaceOnUse"
                        id="Filter_0"
                        x="0px"
                        y="0px"
                        width="349px"
                        height="359px"
                      >
                        <feOffset in="SourceAlpha" dx="-28.284" dy="28.284" />
                        <feGaussianBlur result="blurOut" stdDeviation="7.746" />
                        <feFlood
                          floodColor="rgb(37, 59, 112)"
                          result="floodOut"
                        />
                        <feComposite
                          operator="atop"
                          in="floodOut"
                          in2="blurOut"
                        />
                        <feComponentTransfer>
                          <feFuncA type="linear" slope="0.03" />
                        </feComponentTransfer>
                        <feMerge>
                          <feMergeNode />
                          <feMergeNode in="SourceGraphic" />
                        </feMerge>
                      </filter>
                    </defs>
                    <g filter="url(#Filter_0)">
                      <path
                        fillRule="evenodd"
                        fill="rgb(255, 255, 255)"
                        d="M287.000,30.999 L117.000,30.999 C100.431,30.999 87.000,44.429 87.000,60.998 L87.000,270.999 L287.000,240.999 C303.464,238.598 317.000,227.567 317.000,210.999 L317.000,60.998 C317.000,44.429 303.569,30.999 287.000,30.999 Z"
                      />
                    </g>
                  </svg>
                  <h4>20</h4>
                  <div className="text">Years of Experiences</div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="content-block wow fadeInUp"
                data-wow-duration="1500ms"
              >
                <div className="sec-title mb-20">
                  <div className="sub-title">About Sani Tech Pest Inc.</div>
                  <h2>
                    World Best Pest <br /> Company Since 2004.
                  </h2>
                </div>
                <div className="text">
                  SANI-TECh PEST INC. is a full service I.P.M pest control
                  company located in the BRONX providing service to all 5
                  boroughs for over 20 yrs. Our services are designed to fit the
                  needs of our customers. We provide green services as well
                  as pesticide applications Rodent Proofing, Roaches, Mice, Flies, Bees,
                  Rats and more. <br />
                 All of our service technicians are licensed by the department of environmental conservation we are fully insured.
                </div>
                <div className="link-btn">
                   <Link to="/services" className="theme-btn btn-style-one">
                    <span>Find Services</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
