import { NavLink } from "react-router-dom";

function Index({link}) {
  return (
      <>
    <aside className="sidebar">

                        <div className="sidebar-widget categories">
                            <h3 className="sidebar-title">Pest Control</h3>
                            <div className="widget-content">
            <ul className="list">
              
             <li className={link === "german-roaches" ? "active" : ""}>
                            <NavLink to="/services/german-roaches" activeclassname="active">German Roach Control</NavLink>
                          </li>
                          <li  className={link === "american-roaches" ? "active" : ""}>
                            <NavLink to="/services/american-roaches" activeclassname="active">American Roach Control</NavLink>
                          </li>
                          <li className={link === "ants" ? "active" : ""}>
                            <NavLink to="/services/ants" activeclassname="active">Ant Control</NavLink>
                          </li>
                          <li className={link === "bed-bugs" ? "active" : ""}>
                            <NavLink to="/services/bed-bugs" activeclassname="active">Bedbugs Control</NavLink>
                          </li>
                          <li className={link === "mice" ? "active" : ""}>
                            <NavLink to="/services/mice" activeclassname="active">Mice Control</NavLink>
                          </li >
                          <li className={link === "rat" ? "active" : ""}>
                            <NavLink to="/services/rat" activeclassname="active">Rat Control</NavLink>
                          </li>
                          <li className={link === "fly" ? "active" : ""}>
                            <NavLink to="/services/fly" activeclassname="active">Fly Control</NavLink>
                          </li>
                           <li className={link === "bees" ? "active" : ""}>
                            <NavLink to="/services/bees" activeclassname="active">WASP Control</NavLink>
                          </li>
                                </ul>
                            </div>
                        </div>
                                            
                    </aside>
    </>
  );
}

export default Index;
