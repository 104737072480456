import ServiceBanner from "../../../component/ServiceBanner";
import ServiceSidebar from "../../../component/ServiceSidebar";

function Index() {
  return (
    <>
   <ServiceBanner title="German Roach Control" image="/assets/images/background/german-roaches.jpg" />
    <section className="service-details">
        <div className="auto-container">
            <div className="row">
                <div className="col-lg-8 order-lg-2">
                    <div className="context-block">
                        <h2>German Roach Control</h2>
                        <div className="text">
                                  <p>
                                      Our roach services is tailored to the need of our clients. We employ a system of removal and control that utilizes vacuum cleaners, effective FLUSHING AGENTS, GEL'S & DUST.
                                  </p>
                                  <p>
                                      German cockroaches are <sup>1</sup>&frasl;<sub>2</sub> to <sup>5</sup>&frasl;<sub>8</sub> inch long when mature, light brown to tan, and have fully developed wings. The pronotum or the shield-like segment behind the head has two dark parallel bars on it.
                                  </p>
                           
                                  {/* <p>
                                      The adult males are somewhat narrower than the females when viewed from below. The nymphs, &#8539; inch long when they emerge from the egg capsule, are almost uniformly dark except for a light tan area on the back of the second and third segments. As they develop, the light tan area becomes larger until, as mature nymphs, they have two parallel black bars separated by a light tan area. The purse-shaped egg capsule (ootheca) of the German cockroach is light brown, &#188; to &#8540; inches long, and, typically, has 15 to 20 eggs per side.
                                  </p>
                                  <p>
                                      During her lifetime, the female German cockroach produces four to eight egg capsules each containing 30 to 40 eggs. The female carries the egg capsule partially within her abdomen until just before the nymphs are ready to emerge. Approximately one to two days before hatching, she drops the egg capsule in a protected area. If the egg capsule is dropped prematurely, the developing cockroaches inside die of dehydration. Nymphs molt six to seven times before becoming adults. The process requires about 103 days, thus allowing three to four generations per year. Adults live 100 to 200 days. Established German cockroach populations consist of approximately 75 percent nymphs.
                                  </p> */}
                        </div>
                        {/* <div className="image-block">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="image mb-30"><img src="/assets/images/resource/image-10.jpg" alt="" /></div>
                                </div>
                                <div className="col-md-6">
                                    <div className="image mb-30"><img src="/assets/images/resource/image-11.jpg" alt="" /></div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="col-lg-4 sidebar-side left-sidebar">
                    <ServiceSidebar link="german-roaches" />
                </div>
            </div>
        </div>
    </section>
    </>
  );
}

export default Index;
