import { Link } from "react-router-dom";

function Index() {
  return (
    <>
  <section className="contact-info-section">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-4 info-block">
              <div
                className="inner-box wow fadeInUp"
                data-wow-duration="1500ms"
              >
                <div className="icon">
                  <span className="flaticon-call"></span>
                </div>
                <div className="content text-center">
                  <h5>Call Us Now</h5>
                  <h4>
                    <a href="tel:7186552118">718-655-2118</a>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 info-block">
              <div
                className="inner-box wow fadeInUp"
                data-wow-duration="1500ms"
              >
                <div className="icon">
                  <span className="flaticon-placeholder"></span>
                </div>
                <div className="content text-center">
                  <h5>Coverage Area</h5>
                  <h4>
                    <Link to="/contact-us"> Find Our Location</Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 info-block">
              <div
                className="inner-box wow fadeInUp"
                data-wow-duration="1500ms"
              >
                <div className="icon">
                  <span className="fas fa-calendar"></span>
                </div>
                <div className="content text-center">
                  <h5>Appointment</h5>
                  <h4>
                    <Link to="/appointment">Get Appointment</Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
